import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Avatar from '@mui/material/Avatar'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    CheckBox,
    Delete
} from '@mui/icons-material'
import { BASE_URL } from '../api'
import axios from "axios";

const Card = styled.div`
    position: relative;
    text-decoration: none;
    background: ${({ theme }) => theme.card};
    height: 70px;
    width: 90%;
    display: flex;
    gap: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    &:hover{
        cursor:pointer;
        transform: translateY(-8px);
        transition: all 0 .4s ease-in-out;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
        filter: brightness(1.3);
    }
    
`;
const Top = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    position: relative;
`;
const CardInformation = styled.div`
    display: flex;
    align-items: flex-end;
    font-weight: 450;
    padding: 4px 0px 0px 0px;
    width: 100%;
`;
const MainInfor = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
`;
const Title = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_primary};

`;
const Descript = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 12px;
`;
const CreatorName = styled.div`
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
`;
const AreaBottons = styled.div`
    display: flex;
    gap: 15px;
    color: ${({ theme }) => theme.text_secondary};
`;
const MusicCardTable = ({ music }) => {

    const HandleUpdate = async (id) => {
        try {
            await axios.put(`${BASE_URL}/users/aprovar/${id}`)
                .then(res => {
                    toast.success(res.data.message)
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log('You are Offline')
        }
    }
    const HandleUpdateNotAprove = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/users/notaprovar/${id}`)
                .then(res => {
                    toast.success(res.data.message)
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log('You are Offline')
        }
    }

    return (
        <div>
            <Card key={music.id} >
                <Top>
                    <Avatar sx={{ height: 80, width: 80, fontSize: '24px', }}></Avatar>
                </Top>
                <CardInformation>
                    <MainInfor>
                        <Title> {music.name} </Title>
                        <div style={{ display: 'flex', gap: '15px' }}>
                            <Descript>
                                {music.email}
                            </Descript>
                            <Descript>
                                {music.tipo}
                            </Descript>
                        </div>
                        <CreatorName>{music.estado}</CreatorName>
                    </MainInfor>
                </CardInformation>
                <AreaBottons>
                    <CheckBox onClick={() => HandleUpdate(music.id)} />
                    <Delete onClick={() => HandleUpdateNotAprove(music.id)} />
                </AreaBottons>
            </Card>
            <ToastContainer position="top-center" />
        </div>
    )
}

export default MusicCardTable