import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { BASE_URL } from '../api';
import axios from 'axios';
import img from '../images/4.jpg';
import song from '../assets/audio/Ary - Bida Di Gossi [Versao] (Video Oficial).mp3';
import {
  PlayArrow,
  Pause,
  SkipPrevious,
  SkipNext,
  Repeat,
  VolumeUp,
  VolumeOff,
  RepeatOne,
} from '@mui/icons-material';
import { timer } from '../utils/timer';

const ContainerPlayList = styled.div`
    padding: 20px 30px;
    overflow-y: scroll;
    display: flex;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const ContainerMusicPlayer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
    @media(max-width: 768px){
        display: flex;
        width:100%;
    }
`;
const ContainerListMuisc = styled.div`
    width: 60%;
     display: flex;
     flex-direction: column;
     @media(max-width: 768px){
        display: none;
      }
`;
const TitleList = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    font-size: 24px;
    font-weight: 500;
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const MusicsList = styled.div`
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
`;
const CardMusic = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 12px 12px;
    background: ${({ theme }) => theme.bg};
    border-radius: 10px;
`;
const ImgMusic = styled.img`
   display: flex;
   width: 100px;
   height: 80px;
   border-radius: 10px;
   
`;
const ContainerInfor = styled.div`
    margin-top: 8px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;
const Title = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_primary};
`;
const Descript = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 12px;
     @media(max-width: 768px){
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`;
const TimeMusic = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -20px;
`;
const TimeFinals = styled.div`
  color: ${({ theme }) => theme.text_secondary};
    font-size: 12px;
`;
const ContainerPlayer = styled.div`
   width: 40%;
   height: auto;
   padding: 15px 15px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   background: ${({ theme }) => theme.bg};
    @media(max-width: 768px){
      margin-top: 10px;
      display: flex;
      width: 100%;
      height: 685px;
    }
`;
const ConterItems = styled.div`
   display: flex;
   flex-direction: column;
`;
const ContainerTwo = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    justify-content: center;
`;
const ImgItem = styled.img`
      display: flex;
      width: 350px;
      height: 350px;
      border-radius: 50px;
      box-shadow: 0 0 16px 4px #2E2E2EFF !important;
      @media(max-width: 768px){
       width: 250px;
       height: 450px;
    }
`;
const TilteItem = styled.div`
   overflow: hidden;
    display: -webkit-box;
    font-size: 20px;
    font-weight: 600;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_primary};
`;
const ContainerInpuRange = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const TimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30%;
`;
const TimeInit = styled.div`
    color: ${({ theme }) => theme.text_secondary};
    font-size: 12px;
`;
const ContainerContr = styled.div`
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const ArearBotton = styled.button`
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: orange;
    border: none;
`;
const VolumeContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    border: none;
`;
const ContainerVolume = styled.div`
     margin: 15px;
     display: flex;
     width: 100%;
     flex-direction: row;
     justify-content: space-between;
     gap: 10px;
`;
const TocandoIndex = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border-radius: 10px;
      background: orange;
      position: relative;
      margin-top: 28px;
      margin-left: -25px;
`;
const PlayList = () => {
  const audioRef = useRef(null);
  const [musicNumber, SetmusicNumber] = useState(0);
  const [isplay, SetisPlay] = useState(false);
  const [duration, Setduration] = useState(0);
  const [currenttime, Setcurrenttime] = useState(0);
  const [volume, Setvolume] = useState(50);
  const [Showvolume, SetShowvolume] = useState(false);
  const [repeat, setrepeat] = useState(false);
  const [MusicApi, setMusicApi] = useState([]);

  useEffect(() => {
    GetAllMusic();
  }, [MusicApi]);
  const GetAllMusic = async () => {
    try {

      await axios.get(`${BASE_URL}/audio`)
        .then(res => {
          if (res) {
            setMusicApi(res.data.Response);
          } else {
            alert('You are Offline')
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      alert('You are Offline')
    }
  }

  function handleStart(e) {
    const src = e.nativeEvent.srcElement.src;
    const audio = new Audio(src);

    audio.onloadeddata = function () {
      if (audio.readyState > 0) {
        Setduration(audio.duration)
      }
    }
    if (isplay) { audioRef.current.play() }
  }
  function onTimeUpdate() {
    const currentTime = audioRef.current.currentTime;
    Setcurrenttime(currentTime)
  }
  function ChangeCurrentTime(e) {
    const currentTime = Number(e.target.value);
    audioRef.current.currentTime = currentTime;
    Setcurrenttime(currentTime)

  }
  function handleNextPrev(n) {
    SetmusicNumber(value => {
      if (n > 0)
        return value + n > MusicApi.length - 1 ? 0 : value + n;

      return value + n < 0 ? MusicApi.length - 1 : value + n;
    })

  }

  const handlePlay = () => {
    audioRef.current.play();
    SetisPlay(true);
  }
  const handlePause = () => {
    audioRef.current.pause();
    SetisPlay(false);
  }
  const handlePlayPause = () => {
    if (isplay) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  // function handleRepeat() {
  //   setrepeat(value => {
  //     switch (value) {
  //       case 'Repeat':
  //         return 'RepeatOne';
  //       case 'RepeatOne':
  //         return 'Shuffle';
  //       default:
  //         return 'Repeat'
  //     }
  //   })
  // }

  function EndedAudio() {
    if (repeat) {
      audioRef.current.play();
      setrepeat(true);
    } else {
      setrepeat(false);
      handleNextPrev(1);
    }
  }
  useEffect(() => {
    audioRef.current.volume = volume / 100 // 0-1
  }, [volume]);

  return (
    <ContainerPlayList>

      <ContainerMusicPlayer>
        <ContainerListMuisc>
          <TitleList>Lista de Músicas</TitleList>
          <MusicsList>
            {
              MusicApi.map((MusicApic, index) => (

                <CardMusic onClick={() => SetmusicNumber(index)}>

                  < div style={{ display: 'flex', flexDirection: 'row', }}>
                    <div>
                      <ImgMusic src={`${BASE_URL}/uploads/` + MusicApic.imagem} />
                    </div>
                    {
                      isplay ? (
                        musicNumber === index ?
                          <TocandoIndex>
                            <PlayArrow style={{ width: 18, height: 18, color: '#000' }} />
                          </TocandoIndex>
                          :
                          ''
                      )
                        :
                        ''
                    }
                    <ContainerInfor>
                      <div style={{ marginLeft: '500px' }} >
                        <Duration Music={MusicApic} />
                      </div>
                      <TimeMusic>
                        <Title>{MusicApic.titulo}</Title>
                      </TimeMusic>
                      <Descript>{MusicApic.autor}</Descript>
                      <Descript>{MusicApic.descricao}</Descript>
                    </ContainerInfor>
                  </div>
                </CardMusic>
              ))
            }
          </MusicsList>
        </ContainerListMuisc>
        <ContainerPlayer>
          <ConterItems>
            <ContainerTwo>
              <ImgItem
                src={
                  musicNumber ?
                    (`${BASE_URL}/uploads/` + MusicApi[musicNumber].imagem)
                    :
                    (img)
                }
              />
              <TilteItem>
                {
                  musicNumber ?
                    (MusicApi[musicNumber].titulo)
                    :
                    'Ary-Bida Di Gossi'
                }
              </TilteItem>
              <TilteItem style={{ marginTop: -10, fontSize: '10px' }}>
                {
                  musicNumber ? (MusicApi[musicNumber].autor) : 'Ary'
                }
              </TilteItem>
            </ContainerTwo>
            <ContainerInpuRange>
              <input style={{ width: '100%', }}
                type="range"
                min={0}
                max={duration}
                value={currenttime}
                onChange={e => ChangeCurrentTime(e)}
              />
              <TimerContainer>
                <TimeInit> {timer(currenttime)} </TimeInit>
                <TimeFinals>{timer(duration)}</TimeFinals>
              </TimerContainer>
            </ContainerInpuRange>
            <ContainerContr>
              <div onClick={() => setrepeat(prev => !prev)}>
                {
                  repeat ?
                    <RepeatOne style={{ width: 24, height: 24, color: 'orange' }} />
                    :
                    <Repeat style={{ width: 24, height: 24, color: 'orange' }} />
                }
              </div>
              <SkipPrevious
                style={{ width: 24, height: 24, color: 'orange' }}
                onClick={() => handleNextPrev(-1)}
              />

              <ArearBotton onClick={handlePlayPause}>
                {isplay ? <Pause style={{ width: 24, height: 24, }} />
                  :
                  <PlayArrow style={{ width: 24, height: 24, }} />}
              </ArearBotton>

              <audio
                src={
                  musicNumber ? (`${BASE_URL}/uploads/` + MusicApi[musicNumber].musica)
                    :
                    (song)
                }
                ref={audioRef} onLoadStart={handleStart}
                onTimeUpdate={onTimeUpdate}
                onEnded={EndedAudio}
              />

              <SkipNext
                style={{ width: 24, height: 24, color: 'orange' }}
                onClick={() => handleNextPrev(1)}
              />

              <VolumeUp style={{ width: 24, height: 24, color: 'orange' }}
                onClick={() => SetShowvolume(prev => !prev)}
              />
            </ContainerContr>
            {
              Showvolume ?
                <VolumeContainer>
                  <ContainerVolume>
                    <div onClick={() => Setvolume(v => v > 0 ? 0 : 100)} >
                      {
                        volume === 0 ?
                          <VolumeOff style={{ width: 24, height: 24, color: 'orange' }} />
                          :
                          <VolumeUp style={{ width: 24, height: 24, color: 'orange' }} />
                      }
                    </div>

                    <input style={{ width: '100%', }}
                      type="range"
                      min={0}
                      max={100}
                      value={volume}
                      onChange={e => Setvolume(Number(e.target.value))}
                    />
                    <span>{volume}</span>
                  </ContainerVolume>
                </VolumeContainer>
                :
                <></>
            }
          </ConterItems>
        </ContainerPlayer>
      </ContainerMusicPlayer>
    </ContainerPlayList>
  )
}
export default PlayList

const Duration = ({ Music }) => {

  const [duration, Setduration] = useState(0);
  useEffect(() => {
    const audio = new Audio(`${BASE_URL}/uploads/` + Music.musica)
    audio.onloadedmetadata = function () {
      if (audio.readyState > 0) {
        Setduration(audio.duration)
      }
    }
  }, [Music])
  return (
    <TimeFinals>{timer(duration)}</TimeFinals>
  );

}