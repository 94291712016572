import React, { useContext, useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import styled from 'styled-components'
import MusicCard from '../components/MusicCardFavoritesProfile';
import { Link, useNavigate } from 'react-router-dom';
import { Edit, EmailRounded, Person, LockOpen, PhoneAndroid } from '@mui/icons-material';
import axios from "axios";
import { BASE_URL } from '../api';
import { LoginContext } from '../context/LoginConext';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProfileMain = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const UserDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:left;
    gap: 20px;
    @media (max-width: 950px){
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
   }
`;
const ProfileAvatar = styled.div`
    display: flex;
    padding-left: 3rem;
    @media(max-width: 768px){
        padding-left: 0rem;
    }
    
`;
const ProfileContainer = styled.div`
    display: flex;
    margin-left: -40px;
    flex-direction: column;
    gap: 10px;
     @media (max-width: 950px){
      align-items:center;
      justify-content: center;
   }
`;
const ProfileName = styled.div`
    color: ${({ theme }) => theme.text_primary};
    font-size: 34px;
    font-weight: 500;
    @media(max-width: 768px){
        font-size: 24px;
    }
`;
const ProfileEmail = styled.div`
    color: ${({ theme }) => theme.text_secondary};
    font-size: 14px;
    font-weight: 400;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg};
  border-radius: 10px;
  padding: 20px 30px;
   margin-top: 15px;
`;
const Topic = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center; 
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const Span = styled.div`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    @media(max-width: 768px){
        font-size: 14px;
    }
`;
const Music = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 14px;
   padding: 18px 6px;
   @media (max-width: 950px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
`;
const AreaUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ButtonEdi = styled.button`
    border: none;
    width: 50px;
    height: 50px;
    background: orange;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${({ theme }) => theme.text_primary};
    margin-left: 450%;
    @media(max-width: 768px){
      margin-left: 28%;
      margin-top: 50%;
  }
`;
const ContainerAreaEdit = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg};
  border-radius: 10px;
  padding: 20px 30px;
   margin-top: 15px;
`;
const ContainerFlaxEdit = styled.div`
   display: flex;
  flex-direction: row;
  width: 100%;
    @media(max-width: 768px){
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content:center;
     gap: 20px;
    }
`;
const AraeaImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;
const ContainerInputEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 15px;
  margin-right: 50px;
     @media(max-width: 768px){
      width: 100%;
      margin-top: 20px;
     }
`;
const InputFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;
const InputF = styled.input`
  border-radius: 10px; 
  width: 60%; 
  height:50px; 
  display:flex; 
  background:  #dedede; 
  color: #000;
  padding-left: 20px;
  border:none;
   @media(max-width: 768px){
       width: 100%;
     }
`;
const InputFlexButton = styled.div`
@media(max-width: 768px){
     display: flex;
     align-items: center;
     justify-content:center;
    }
`;
const InputButton = styled.button`
    margin-top: 20px;
    border-radius: 8px;
    width: 80px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 18px;
    color: #fff;
    background: orange;
    border: none;
    `;
const Profile = () => {
  const { userLogged } = useContext(LoginContext);
  const [Musics, setMusics] = useState([]);
  const [IsEdit, setIEdit] = useState(false);

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [foto, setfoto] = useState('');
  const [password, setpassword] = useState('');
  const [contacto, setcontacto] = useState('');
  const [user_id, setuser_id] = useState('');

  const Navigation = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('id')
    setuser_id(id)
    if (!token) {
      Navigation('/');
    } else {
      const HandleSearch = async () => {
        try {
          await axios.get(`${BASE_URL}/favorites/profile/users/${id}`)
            .then(res => {
              setMusics(res.data.Response);
              console.log(res)
            })
            .catch(err => console.log(err));
        } catch (error) {
          console.log('You are Offline')
        }
      }
      HandleSearch();
    }
  }, [])

  const handleUpload = async () => {
    try {
      const formdata = new FormData();
      formdata.append('name', name);
      formdata.append('email', email);
      formdata.append('password', password);
      formdata.append('contacto', contacto);
      formdata.append('foto', foto);
      formdata.append('id', user_id);

      await axios.put(`${BASE_URL}/users/update`, formdata)
        .then(response => {
          toast.success(response.data.message)
          console.log(response)
        })
        .catch(err =>
          console.log(err)
        )

    } catch (err) {
      toast.error('Usuario Não Autenticado!')
    }

  }

  return (
    <ProfileMain>
      <UserDetails>
        <ProfileAvatar>
          <AreaUser>
            {
              userLogged.fotoperfil ?
                (<img
                  style={{ height: '165px', width: '165px', borderRadius: '50px', }}
                  src={`${BASE_URL}/fotosperfil/` + userLogged.fotoperfil} alt={userLogged.name} />)
                :
                (<Avatar sx={{ height: 165, width: 165, fontSize: '24px', }}></Avatar>)
            }
            <ButtonEdi onClick={() => setIEdit(prev => !prev)}>
              <Edit />
            </ButtonEdi>
          </AreaUser>

        </ProfileAvatar>
        <ProfileContainer>
          <ProfileName> {userLogged.name} </ProfileName>
          <ProfileEmail>{userLogged.email}</ProfileEmail>
          <ProfileEmail>{userLogged.tipo}</ProfileEmail>
        </ProfileContainer>
      </UserDetails>
      {
        IsEdit ?
          (
            <FilterContainer>
              <Topic>
                Profile Update
              </Topic>
              <ContainerAreaEdit>
                <ContainerFlaxEdit>
                  <AraeaImage>
                    {
                      userLogged.fotoperfil ?
                        (<img
                          style={{ height: 165, width: 165, borderRadius: '50px', }}
                          src={`${BASE_URL}/fotosperfil/` + userLogged.fotoperfil} alt={userLogged.name} />)
                        :
                        (<Avatar sx={{ height: 165, width: 165, fontSize: '24px', }}></Avatar>)
                    }
                    <input
                      type="file"
                      name='foto'
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => { setfoto(e.target.files[0]) }}
                    />
                  </AraeaImage>
                  <ContainerInputEdit>
                    <InputFlex>
                      <Person style={{ color: 'orange' }} />
                      <InputF
                        type='text'
                        name='name'
                        placeholder={userLogged.name}
                        onChange={(e) => { setname(e.target.value) }}
                      />
                    </InputFlex>
                    <InputFlex>
                      <EmailRounded style={{ color: 'orange' }} />
                      <InputF
                        type='email'
                        name='email'
                        placeholder={userLogged.email}
                        onChange={(e) => { setemail(e.target.value) }}
                      />
                    </InputFlex>
                    <InputFlex>
                      <LockOpen style={{ color: 'orange' }} />
                      <InputF
                        type='password'
                        name='password'
                        placeholder={userLogged.password}
                        onChange={(e) => { setpassword(e.target.value) }}
                      />
                    </InputFlex>
                    <InputFlex>
                      <PhoneAndroid style={{ color: 'orange' }} />
                      <InputF
                        type='number'
                        name='contacto'
                        placeholder={userLogged.contacto}
                        onChange={(e) => { setcontacto(e.target.value) }}
                      />
                    </InputFlex>
                    < InputFlexButton>
                      <InputButton
                        onClick={handleUpload}
                      >
                        Save
                      </InputButton>
                    </InputFlexButton>
                  </ContainerInputEdit>
                </ContainerFlaxEdit>
              </ContainerAreaEdit>
            </FilterContainer>
          )
          :
          (
            <FilterContainer>
              <Topic>
                Teus Favouritos
                <Link to={`/favorite`}
                  style={{ textDecoration: 'none' }} >
                  <Span>Todas Músicas </Span>
                </Link>
              </Topic>
              <Music>
                {Musics.map((Musics) => (
                  <MusicCard
                    music={Musics}
                  />
                )
                )}
              </Music>
            </FilterContainer>
          )
      }
      <ToastContainer position="top-center" />
    </ProfileMain>
  )
}

export default Profile