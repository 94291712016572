import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../api";

export const LoginContext = createContext();

export const LoginContextProvider = ({ children, value }) => {
  const [userAuth, setuserAuth] = useState([]);
  const [userLogged, setuserLogged] = useState('');



  const Login = async ({ email, password }) => {
    if (!email || !password) {
      toast.warn("Campos Obrigatorios")
    } else {
      try {
        await axios
          .post(`${BASE_URL}/users/login`, {
            email: email,
            password: password,
          })
          .then(response => {
            localStorage.setItem('id', response.data.response.id);
            localStorage.setItem('token', response.data.kalepa_token);
            setuserAuth(response.data.response.id)
            toast.success(response.data.message);
            setuserAuth(response.data.response)
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch(error => {
            toast.warn(error.response.data.message)
          })
      }
      catch (error) {
        toast.error('Network Request Error');
      }
    }
  }
  useEffect(() => {
    GetUserAuth()
  })
  const GetUserAuth = async () => {
    try {
      const id = localStorage.getItem('id');
      await axios.get(`${BASE_URL}/users/${id}`)
        .then(res => {
          if (res) {
            setuserLogged(res.data.Response[0])
          } else {
            toast.warn('You are Offline')
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }

  return (
    <LoginContext.Provider value={{ userAuth, setuserAuth, Login, userLogged }}>
      {children}
      <ToastContainer position="top-center" />
    </LoginContext.Provider>
  )
}