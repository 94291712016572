import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from '../api';
const background = {
    display: 'flex',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
    zIndex: '1000',
}
const modal = {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    height: '720px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffff',
    borderRadius: '10px',
}
const TopArea = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '30px',
    padding: '25px',
}
const TitleArea = {
    fontSize: '20px',
    fontweight: '500',
}
const ButtonClose = {
    border: 'none',
    backgroundColor: 'transparent',
}
const SectionModal = {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '25px',
    gap: 20,
}

const Input = {
    width: '80%',
    height: '40px',
    border: '1px solid gray',
    borderRadius: '15px',
    padding: 10,
}
const InputSelect = {
    width: '86%',
    height: '60px',
    border: '1px solid gray',
    borderRadius: '15px',
    padding: 10,
}
const ContainerInputs2 = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    marginTop: -20,
}
const ButtonLogin = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: '50px',
    border: 'none',
    borderRadius: '10px',
    padding: 15,
    marginLeft: -55,
    fontSize: 18,
    backgroundColor: 'orange'
}

const Label = {
    fontSize: 14,
    fontWeight: 400,
}
const ModalRegister = ({ IsRegister, setIsRegister, setIsEditOpen }) => {

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [contacto, setcontacto] = useState('');
    const [tipo, settipo] = useState('');

    const HandleRegister = async () => {
        if (!name || !email || !password || !contacto || !tipo) {
            toast.warn("Campos Obrigatorios")
        } else {
            try {
                await axios
                    .post(`${BASE_URL}/users/`, {
                        name: name,
                        email: email,
                        password: password,
                        contacto: contacto,
                        tipo: tipo,
                    })
                    .then(response => {
                        toast.success(response.data.message);
                        console.log(response)
                    })
                    .catch(error => {
                        toast.error(error.response.data.message)
                        console.log(error)
                    })
            }
            catch (error) {
                toast.error('Network Request Error');
            }
        }
    };
    const OpenRegisterModal = () => {
        setIsRegister(false);
        setIsEditOpen(true);
    }

    if (IsRegister) {
        return (
            <div style={background}>
                <div style={modal}>
                    <div style={TopArea}>
                        <span style={TitleArea}>Faça Regitro</span>
                        <button style={ButtonClose}
                            onClick={setIsRegister}>
                            <Close />
                        </button>
                    </div>
                    <div style={SectionModal}>
                        <div style={ContainerInputs2}>
                            <span style={Label}>Nome</span>
                            <input
                                style={Input}
                                type="text"
                                id=""
                                placeholder='Insiara o seu Nome'
                                onChange={(event) => { setname(event.target.value) }}
                                required
                            />
                            <span style={Label}>Email</span>
                            <input
                                style={Input}
                                type="email"
                                name="email"
                                id=""
                                placeholder='Insira o seu Email'
                                onChange={(event) => { setemail(event.target.value) }}
                                required
                            />
                            <span style={Label}>Numero</span>
                            <input
                                style={Input}
                                type="number"
                                name="contacto"
                                id=""
                                placeholder='Insira o seu telefone'
                                onChange={(event) => { setcontacto(event.target.value) }}
                                required
                            />
                            <span style={Label}>Password</span>
                            <input
                                style={Input}
                                type="password"
                                name="password"
                                id=""
                                placeholder='Insira a sua Password'
                                onChange={(event) => { setpassword(event.target.value) }}
                                required
                            />
                            <span style={Label}>Tipo de Usuario</span>
                            <select
                                name="tipo"
                                id=""
                                style={InputSelect}
                                onChange={(event) => { settipo(event.target.value) }}
                                required
                            >
                                <option >Select Tipo de Usuario</option>
                                <option value="Musico" >Musico</option>
                                <option value="Ouvinte" >Ouvinte</option>
                                <option value="Gestor" >Gestor de Carreira</option>
                            </select>
                        </div>
                        <button onClick={HandleRegister} style={ButtonLogin}>
                            <span>Registrar</span>
                        </button>
                        <button
                            onClick={OpenRegisterModal}
                            style={{ border: 'none', background: 'transparent', marginLeft: -60, }}
                        >
                            <span>Ja tens uma Conta? click Aqui!</span>
                        </button>
                    </div>
                </div>
                <ToastContainer autoClose={5000} position="top-center" />
            </div>
        )
    }
    return null;
}

export default ModalRegister