

import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
    padding: 10px;
    border-radius: 6%;
    width: 250px;
    height: 180px;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 8px 4px #0F0E1050 !important;
`;
const DefaultCardText = styled.div`
    color: white;
    font-size: 18px;
    font-weight: 500;
`;
const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const CategoriesCard = ({ category }) => {
    return (
        <Card style={{ "background-color": 'orange' }}>
            <DefaultCardText> {category.categoria} </DefaultCardText>
            <FlexContainer>
            </FlexContainer>
        </Card>
    )
}

export default CategoriesCard