import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Avatar from '@mui/material/Avatar'
import { IconButton } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Headphones, Pause } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from '../api';
const PlayIcon = styled.button`
    padding: 10px;
    border-radius: 50%;
    z-index: 100;
    align-items: center;
    background: orange;
    color: white !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: absolute !important;
    top: 45%;
    right: 10%;
    border: none;
    display: flex;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 16px 4px #9000ff50 !important;
`;
const Card = styled.div`
    position: relative;
    text-decoration: none;
    background: ${({ theme }) => theme.card};
    max-width: 220px;
    height: 280px;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:center;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    &:hover{
        cursor:pointer;
        transform: translateY(-8px);
        transition: all 0 .4s ease-in-out;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
        filter: brightness(1.3);
    }
    
`;
const Top = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    position: relative;
`;
const Favorite = styled(IconButton)`
    color: white;
    top: 8px;
    right:6px;
    padding: 6px !important;
    border-radius: 50%;
    z-index: 100;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.text_secondary + 95} !important;
    color: white !important;
    position: absolute !important;
    backdrop-filter: blur(4px);
    box-shadow: 0 0 16px 4px #2E2E2EFF !important;
`;
const CardImage = styled.img`

    object-fit: fill;
    width: 210px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    &:hover{
         box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4); 
    }
`;
const CardInformation = styled.div`
    display: flex;
    align-items: flex-end;
    font-weight: 450;
    padding: 4px 0px 0px 0px;
    width: 100%;
`;
const MainInfor = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
`;
const Title = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_primary};

`;
const Descript = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 12px;
`;
const Createinfor = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 6px;
`;
const Creator = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
const CreatorName = styled.div`
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
     color: ${({ theme }) => theme.text_secondary};
`;
const Likes = styled.div`
    display: flex;
    font-size: 10px;
    color: ${({ theme }) => theme.text_secondary};
    width: max-content;
    justify-content: space-between;
`;
const MusicCardDisplay = ({ music }) => {

    const [currentAudio, setCurrentAudio] = useState(false);
    const [isPlaying, setIsPlaying] = useState(null);
    const [isFavorites, setisFavorites] = useState(false);
    const [number, setnumber] = useState(1);
    const [user_id, setuser_id] = useState('');
    const [music_id, setmusic_id] = useState('');
    const audioRefs = useRef(null);


    useEffect(() => {

        setuser_id(localStorage.getItem('id'))
        setmusic_id(music.id)

            const favorites = async () => {
                const id = music.id

                try {
                    await axios.post(`${BASE_URL}/favorites/users`,{
                        user_id: user_id,
                        projecto_id: music_id
                    })
                        .then(res => {
                            setisFavorites(res.data.Response);
                            console.log(res)
                        })
                        .catch(err => console.log(err));
                } catch (error) {
                    console.log('You are Offline')
                }
            }
            favorites()
    });

    const handlePlay = () => {
        audioRefs.current.play();
        setCurrentAudio(true);
      }
      const handlePause = () => {
        audioRefs.current.pause();
        setCurrentAudio(false);
      }
      const handlePlayPause = () => {
        if (currentAudio) {
          handlePause();
        } else {
          handlePlay();
        }
      };

    const AddFaforites = async (id) => {
        if(!user_id){
            toast.warn('Usuario Não Autenticado')
        }else{
        try {
            await axios.post(`${BASE_URL}/favorites/`, {
                number: number,
                user_id: user_id,
                projecto_id: music_id,
            })
                .then(res => {
                    setisFavorites(res.data.Response);
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log('You are Offline')
        }
    }
    }

    return (
        <div>
            <Card key={music.id} >
                <div>

                    <Top>
                        <Favorite onClick={() => AddFaforites(music.id)}>
                            {
                                isFavorites ? (<FavoriteIcon style={{ width: "16px", height: "16px", color: 'orange' }} />)
                                    :
                                    (<FavoriteIcon style={{ width: "16px", height: "16px", }} />)
                            }
                        </Favorite>
                        <Link
                            to={`/music/${music.id}`}
                            style={{ textDecoration: "none" }}>
                            <CardImage src={`${BASE_URL}/uploads/` + music.imagem} />
                        </Link>
                    </Top>
                    <CardInformation>
                        <MainInfor>
                            <Title> {music.titulo} </Title>
                            <Descript>
                                {music.descricao}
                            </Descript>
                            <CreatorName>{music.autor} </CreatorName>
                            <Createinfor>
                                <Creator>
                                    <CreatorName>Publicado por:  {music.name}</CreatorName>
                                </Creator>
                            </Createinfor>
                        </MainInfor>
                    </CardInformation>

                </div>
                <audio ref={audioRefs} src={`${BASE_URL}/uploads/` + music.musica} />
                <PlayIcon onClick={handlePlayPause} >
                    {currentAudio ? <Pause /> : <Headphones />}
                </PlayIcon>
            </Card >
            <ToastContainer position="top-center" />
        </div >
    )
}

export default MusicCardDisplay