import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { BASE_URL } from '../api';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { FaThumbsUp } from 'react-icons/fa';
import {
  Download,
  Favorite,
  Headphones,
  Pause,
  MessageSharp,
  Close,
} from '@mui/icons-material'
import Avatar from '@mui/material/Avatar'
import { useParams } from 'react-router-dom';

const MusicDatailsContainer = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media(max-width: 812px){
      display: flex;
      flex-direction: column;
    }
`;
const Deatailsimg = styled.img`
  width: 430px;
  height: 280px;
  display: flex;
  object-fit: fill;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4); 
  @media(max-width: 512px){
        width: 250px;
        height: 190px;
    }
  &:hover{
        box-shadow: 0 4px 30px white; 
    }
`;
const DetailInfor = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 5px;
`;
const Title = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 24px;
    color: ${({ theme }) => theme.text_primary};
`;
const Descrition = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 15px;
    @media(max-width: 812px){
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       text-overflow: ellipsis;
    }
`;
const CreatorInfo = styled.div`
   display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 6px;
`;
const Creator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
`;
const CreatorName = styled.div`
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
`;
const PlayIcon = styled.div`
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background: orange;
    border-radius: 50%;
    color: white !important;
    box-shadow: 0 0 10px 4px #51505350 !important;
`;
const Section = styled.div`
   display: flex;
   flex-direction: column;
`;
const TitleSection = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 24px;
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 20px;
`;
const ContainerMusicsDatails = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.bg};
    border-radius: 10px;
    padding: 20px 30px;
`;
const AllmusicCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    @media(max-width: 614px){
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;
const MusicImg = styled.img`
      border-radius: 6px;
      width: 70px;
      height: 50px
      object-fit: fill;
      display: flex;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4); 
     @media(max-width: 614px){
        width: 250px;
        height: 190px;
    }
  &:hover{
        box-shadow: 0 4px 30px white; 
    }
`;
const MusilInfor = styled.div`
    display: flex;
    flex-direction: column;
    @media(max-width: 614px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;
const MusicTitle = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 14px;
    color: ${({ theme }) => theme.text_primary};
`;
const DescriptionMusicA = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 15px;
    @media(max-width: 830px){
       -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       text-overflow: ellipsis;
    }
`;
const DescriptionMusic = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 15px;
    @media(max-width: 830px){
       -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       text-overflow: ellipsis;
    }
`;
const ContainerComent = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.7);
    z-index: 1000;
`;
const AreaComent = styled.div`
    display: flex;
    width: 450px;
    height: 480px;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffff;
    border-radius: 20px;
`;
const AreaInput = styled.div`
   display: flex;
   width: 100%;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap:8px;
   position: fixed;
   top: 80%;
   bottom: 0;
   margin-bottom: 10px;
`;
const AreaUserComented = styled.div`
    display: flex;
    width: 100%;
    margin: 10px;
    margin-bottom: 10px;
`;
const ListUser = styled.div`
     display: flex;
     width: 100%;
     height: 100%;
     flex-direction: column;  
`;
const CradUserComet = styled.div`
`;
const AreaHeader = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 5px;
`;
const ContainerComentario = styled.div`
   margin-top: 4px;
   margin-left: 40px; 
   display: flex;
   width: 80%;
   height: 80px;
   border-radius: 20px;
   padding: 10px;
   background:#f0f0f0;
`;
const SpanComent = styled.p`
   overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    margin-top: -1px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 14px;
`;
const Div = styled.div`
  overflow-x:hidden;
`;

const MusicDatails = () => {

  const [isplay, SetisPlay] = useState(false);
  const [AllmusicAutor, setAllmusicAutor] = useState([]);
  const [iscoment, Setiscoment] = useState(false);
  const [coment, Setcoment] = useState('');
  const [totalcomet, Settotalcoment] = useState('');
  const params = useParams();
  const id = params['id'];

  const audioRef = useRef(null);

  const [MusicApi, setMusicApi] = useState([]);

  const [number, setnumber] = useState(1);
  const [gosto, setgosto] = useState(1);
  const [user_id, setuser_id] = useState('');
  const [totalFavorites, settotalFavorites] = useState('');
  const [totalGostos, settotalGostos] = useState('');
  const [totalDowload, settotalDowload] = useState('');
  const [Allcoment, setAllcoment] = useState([]);

  useEffect(() => {
    const GetAllComent = async () => {
      try {
        await axios.get(`${BASE_URL}/comentarios/audio/${id}`)
          .then(res => {
            if (res) {
              setAllcoment(res.data.Response);
            } else {
              toast.warn('You are Offline')
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        toast.warn('You are Offline')
      }
    }
    const GetTotalComent = async () => {
      try {
        await axios.get(`${BASE_URL}/comentarios/total/${id}`)
          .then(res => {
            if (res) {
              Settotalcoment(res.data.Response[0].COUNT);
            } else {
              toast.warn('You are Offline')
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        toast.warn('You are Offline')
      }
    }
    const GetTotalFavorites = async () => {
      try {
        await axios.get(`${BASE_URL}/favorites/total/${id}`)
          .then(res => {
            if (res) {
              settotalFavorites(res.data.Response[0].COUNT);
            } else {
              toast.warn('You are Offline')
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        toast.warn('You are Offline')
      }
    }
    const GetTotalGosotos = async () => {
      try {
        await axios.get(`${BASE_URL}/gostos/total/${id}`)
          .then(res => {
            if (res) {
              settotalGostos(res.data.Response[0].COUNT);
            } else {
              toast.warn('You are Offline')
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        toast.warn('You are Offline')
      }
    }
    const GetTotalDownloads = async () => {
      try {
        await axios.get(`${BASE_URL}/downloads/total/${id}`)
          .then(res => {
            if (res) {
              settotalDowload(res.data.Response[0].COUNT);
            } else {
              toast.warn('You are Offline')
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        toast.warn('You are Offline')
      }
    }
    GetTotalComent()
    GetAllComent()
    GetTotalDownloads()
    GetTotalGosotos()
    GetTotalFavorites()
    setuser_id(localStorage.getItem('id'))
    GetAllMusic();
  })

  const GetAllMusic = async () => {
    try {

      await axios.get(`${BASE_URL}/audio/${id}`)
        .then(res => {
          if (res) {
            setMusicApi(res.data.Response[0])
            const autor = res.data.Response[0].autor;

            try {
              axios.post(`${BASE_URL}/audio/music/autor/`, { autor: autor })
                .then(res => {
                  if (res) {
                    setAllmusicAutor(res.data.Response);
                  } else {
                    toast.warn('You are Offline')
                  }
                })
                .catch(err => console.log(err));
            } catch (error) {
              toast.warn('You are Offline')
            }
          } else {
            toast.warn('You are Offline')
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }

  const handlePlay = () => {
    audioRef.current.play();
    SetisPlay(true);
  }
  const handlePause = () => {
    audioRef.current.pause();
    SetisPlay(false);
  }
  const handlePlayPause = () => {
    if (isplay) {
      handlePause();
    } else {
      handlePlay();
    }
  };
  const AddFaforites = async (post_id) => {
    if (!user_id) {
      toast.warn('Usuario Não Autenticado')
    } else {
      try {
        await axios.post(`${BASE_URL}/favorites/`, {
          number: number,
          user_id: user_id,
          projecto_id: post_id,
        })
          .then(res => {

          })
          .catch(err => console.log(err));
      } catch (error) {
        console.log('You are Offline')
      }
    }
  }

  const AddGosto = async (post_id) => {
    if (!user_id) {
      toast.warn('Usuario Não Autenticado')
    } else {
      try {
        await axios.post(`${BASE_URL}/gostos/`, {
          gosto: gosto,
          usuario_id: user_id,
          projecto_id: post_id,
        })
          .then(res => {
          })
          .catch(err => console.log(err));
      } catch (error) {
        console.log('You are Offline')
      }
    }
  }
  const AddComent = async (post_id) => {
    if (!user_id) {
      toast.warn('Usuario Não Autenticado')
    } else if (!coment) {
      toast.warn('Porfavor descerve o comentario ')
    } else {
      try {
        await axios.post(`${BASE_URL}/comentarios/`, {
          descricao: coment,
          usuario_id: user_id,
          projecto_id: post_id,
        })
          .then(res => {
            Setcoment('')
          })
          .catch(err => console.log(err));
      } catch (error) {
        console.log('You are Offline')
      }
    }
  }
  const AddDownloads = async (post_id) => {
    try {
      await axios.post(`${BASE_URL}/downloads/`, {
        numero: number,
        usuario_id: user_id,
        projecto_id: post_id,
      })
        .then(res => {
        })
        .catch(err => console.log(err));
    } catch (error) {
      console.log('You are Offline')
    }
    try {
      const response = await axios.get(`${BASE_URL}/downloads/audio/${post_id}`);
      const url = `${BASE_URL}/uploads/` + response.data.file;

      const filename = url.split("/").pop();
      const Atag = document.createElement("a");
      Atag.href = url;
      Atag.setAttribute("download", filename);
      document.body.appendChild(Atag);
      Atag.click();
      Atag.remove();

      // setDownloading(true);
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'download.mp3';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // setTimeout(() => {
      //   setDownloading(false);
      // }, 2000);


    } catch (error) {
      console.error('Erro ao fazer download do arquivo:', error);

    }
  }
  return (
    <MusicDatailsContainer>
      <Header>
        <Deatailsimg src={`${BASE_URL}/uploads/` + MusicApi.imagem} />
        <DetailInfor>
          <Title>{MusicApi.titulo}</Title>
          <Descrition style={{ fontSize: "10px" }}>
            {MusicApi.autor}
          </Descrition>
          <Descrition style={{ fontSize: "12px" }}>
            {MusicApi.descricao}
          </Descrition>
          <CreatorInfo>
            <Creator>
              <PlayIcon >
                <PlayIcon onClick={() => AddFaforites(MusicApi.id)}>
                  <Favorite style={{ width: "18px", height: "18px", }} />
                  <span style={{ fontWeight: 'bold', marginTop: '28px', marginRight: '-10px', fontSize: '15px', position: 'relative' }} >{totalFavorites}</span>
                </PlayIcon>
              </PlayIcon>
              <PlayIcon onClick={() => AddGosto(MusicApi.id)} >
                <FaThumbsUp style={{ width: "18px", height: "18px" }} />
                <span style={{ fontWeight: 'bold', marginTop: '28px', marginRight: '-10px', fontSize: '15px', position: 'relative' }} >{totalGostos}</span>
              </PlayIcon>
              <PlayIcon>
                <MessageSharp
                  onClick={() => Setiscoment(prev => !prev)}
                  style={{ width: "18px", height: "18px" }} />
                <span style={{ fontWeight: 'bold', marginTop: '28px', marginRight: '-10px', fontSize: '15px', position: 'relative' }} >{totalcomet}</span>
              </PlayIcon>
              <PlayIcon onClick={() => AddDownloads(MusicApi.id)} >
                <Download style={{ width: "18px", height: "18px" }} />
                <span style={{ fontWeight: 'bold', marginTop: '28px', marginRight: '-10px', fontSize: '15px', position: 'relative' }} >{totalDowload}</span>
              </PlayIcon>
            </Creator>

          </CreatorInfo>
          {
            iscoment ? (
              <ContainerComent>
                <AreaComent>
                  <div style={{
                    margin: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                    <span style={{ fontSize: '20px', }} >Comentarios</span>
                    <Close onClick={() => Setiscoment(false)} />
                  </div>
                  <Div >
                    <AreaUserComented>
                      <ListUser>
                        {
                          Allcoment.map((Allcoment) => (
                            <CradUserComet>
                              <AreaHeader>
                                {
                                  Allcoment.fotoperfil ? (
                                    <img src={`${BASE_URL}/fotosperfil/` + Allcoment.fotoperfil}
                                      style={{ height: 50, width: 50, borderRadius: 50, }}
                                      alt='Textando' />)
                                    :
                                    (<Avatar sx={{ height: 40, width: 40, fontSize: '24px', }}></Avatar>)
                                }
                                <span style={{ fontSize: 14, }}> {Allcoment.name} </span>
                                <span style={{ marginLeft: '28%', fontSize: 14, }} >{Allcoment.created_at}</span>
                              </AreaHeader>
                              <ContainerComentario>
                                <SpanComent>
                                  {Allcoment.descricao}
                                </SpanComent>
                              </ContainerComentario>
                            </CradUserComet>
                          ))
                        }
                      </ListUser>
                    </AreaUserComented>
                  </Div>
                  <AreaInput>
                    <input
                      style={{
                        width: "80%",
                        height: "50px",
                        borderRadius: '20px',
                        paddingLeft: '10px',
                      }}
                      type="text"
                      placeholder='Add your comentario!'
                      onChange={(event) => { Setcoment(event.target.value) }}
                    />
                    <button
                      onClick={() => AddComent(MusicApi.id)}
                      style={{
                        display: 'flex',
                        border: 'none',
                        background: "orange",
                        padding: "10px",
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50px',
                      }}
                    ><MessageSharp /></button>
                  </AreaInput>
                </AreaComent>

              </ContainerComent>
            ) : (<></>)
          }
          <CreatorInfo> 
            <Creator>
              <CreatorName>Publicado por: {MusicApi.name}</CreatorName>
              <PlayIcon onClick={handlePlayPause}>
                {isplay ? <Pause /> : <Headphones />}
              </PlayIcon>
              <audio
                ref={audioRef}
                src={`${BASE_URL}/uploads/` + MusicApi.musica}
              />
            </Creator>
          </CreatorInfo>
        </DetailInfor>
      </Header>

      <Section>
        <TitleSection>Todas Musicas</TitleSection>
        <div style={{ gap: "20px", display: "flex", flexDirection: "column", }} >
          {AllmusicAutor.map((AllmusicAutor) => (
            <CardAllMusicUser music={AllmusicAutor} />
          )
          )}
        </div>
      </Section>
      <ToastContainer position="top-center" />
    </MusicDatailsContainer>
  )
}

const CardAllMusicUser = ({ music }) => {

  const [isplay, SetisPlay] = useState(false);

  const audioRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.play();
    SetisPlay(true);
  }
  const handlePause = () => {
    audioRef.current.pause();
    SetisPlay(false);
  }
  const handlePlayPause = () => {
    if (isplay) {
      handlePause();
    } else {
      handlePlay();
    }
  };
  return (
    <ContainerMusicsDatails>
      <AllmusicCard key={music.id}>
        <MusicImg src={`${BASE_URL}/uploads/` + music.imagem} />
        <MusilInfor>
          <MusicTitle>{music.titulo} </MusicTitle>
          <DescriptionMusicA style={{ fontSize: "10px" }} >
            {music.autor}
          </DescriptionMusicA>
          <DescriptionMusic style={{ fontSize: "11px" }}>
            {music.descricao}
          </DescriptionMusic>
          <CreatorInfo>
            <audio ref={audioRef} src={`${BASE_URL}/uploads/` + music.musica} />
            <Creator>
            <PlayIcon onClick={handlePlayPause}>
              {isplay ? <Pause /> : <Headphones />}
            </PlayIcon>
            </Creator>
          </CreatorInfo>
        </MusilInfor>
      </AllmusicCard>
    </ContainerMusicsDatails>

  );
}

export default MusicDatails