import { Close, } from '@mui/icons-material';
import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginContext } from '../context/LoginConext';
import {
    CloudUpload,
    MusicNote,
} from '@mui/icons-material';
import { BASE_URL } from '../api';
const Background = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.7);
    z-index: 1000;
`;
const ContainerUpload = styled.div`
    display: flex;
    width: 520px;
    height: 720px;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffff;
    border-radius: 10px;
    @media(max-width: 768px){
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
      width: auto;
      height: 700px;
      padding: 30px;
     }
`;
const TopArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
`;
const TitleArea = styled.div`
    font-size: 20px;
    font-weight: 500;
`;
const ButtonClose = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
`;
const SectionUpload = styled.div`
    display: flex;
    margin-top: -20px;
    flex-direction: column;
    width: 100%;
    padding: 20px;
   margin-bottom: 30px;
`;
const TitleSection = styled.div`
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
`;
const ContainerInputs = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin-left: -5px;
     @media(max-width: 768px){
      width: auto;
     }
`;
const InputFlex = styled.div`
`;
const InputFile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 94%;
    height: 100px;
    border: 1px dashed #000;
    border-radius: 10px;
`;
const Input = styled.input`
    display: flex;
    width: 90%;
    height: 50px;
    border: 1px solid #000;
    border-radius: 10px;
    padding-left: 15px;
`;
const TextArea = styled.textarea`
    display: flex;
    width: 90%;
    height: 65px;
    padding-top: 15px;
    padding-left: 15px;
    border: 1px solid #000;
    border-radius: 10px;
`;
const Select = styled.select`
    display: flex;
    width: 94%;
    height: 50px;
    color: #B2B2B2FF;
    padding-left: 15px;
    border: 1px solid #000;
    border-radius: 10px;
`;
const ButtonUpload = styled.button`
    display: flex;
    width: 94%;
    height: 55px;
    border: none;
    align-items: center;
    justify-content: center;
    background: orange;
    border-radius: 10px;
`;
const Loader = styled.div`
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 40%;
    right: 50%;
    z-index: 1000;
`;

const ModalUploadMusic = ({ IsModalUpMusic, setIsModalUpMusic }) => {
    const [Loading, setLoading] = useState(false);

    const [musica, setaudio] = useState();
    const [number, setnumber] = useState(1);
    const [imagem, setimage] = useState();
    const [autor, setautor] = useState();
    const [titulo, settitulo] = useState();
    const [idCat, setidCat] = useState('');
    const [descricao, setdescricao] = useState();
    const [catSelet, setcatSelet] = useState();
    const [user_id, setuser_id] = useState();
    const [Categoria, setCategoria] = useState([]);

    const { userLogged } = useContext(LoginContext);

    useEffect(() => {
        setuser_id(localStorage.getItem('id'));
    }, [user_id])

    const handleUpload = async () => {
        if (!musica || !imagem || !autor || !titulo || !descricao || !catSelet || !Categoria) {
            toast.error('Campos Obrigatorio!')
        } else {
            try {
                const formdata = new FormData();

                formdata.append('titulo', titulo);
                formdata.append('descricao', descricao);
                formdata.append('imagem', imagem);
                formdata.append('user_id', user_id);
                formdata.append('categoria_id', catSelet);
                formdata.append('musica', musica);
                formdata.append('autor', autor);

                await axios.post(`${BASE_URL}/audio`, formdata)
                    .then(response => {
                        toast.success(response.data.message)
                      axios.post(`${BASE_URL}/notification`,{
                        number: number,
                        user_id: user_id,
                        post_id: response.data.user_id,
                      })
                        .then(response => {
                            // console.log(response)
                        })
                        .catch(err =>
                            console.log(err)
                        )
                    })
                    .catch(err =>
                        console.log(err)
                    )

            } catch (err) {
                toast.error('Usuario Não Autenticado!')
            }
        }
    }
    const GetCategoria = async () => {
        try {
            await axios.get(`${BASE_URL}/categorias`)
                .then(res => {
                    setCategoria(res.data.Response);
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetCategoria();
    }, []);
    const HandleClose = () => {
        window.location.reload()
        setIsModalUpMusic(false)
    }
    const UploadMusic = () => {
        handleUpload();
    }
    if (IsModalUpMusic) {
        return (
            <Background>
                <ContainerUpload>
                    {
                        Loading ? (
                            <Loader>
                                <CircularProgress style={{ color: 'orange' }} />
                            </Loader>
                        ) : (
                            <></>
                        )
                    }
                    <TopArea>
                        <TitleArea>Upload de Musica</TitleArea>
                        <ButtonClose onClick={HandleClose}>
                            <Close />
                        </ButtonClose>
                    </TopArea>
                    <SectionUpload>
                        <TitleSection>Musica Details:</TitleSection>
                        <ContainerInputs>
                            <InputFile>
                                <CloudUpload style={{ fontSize: 28, color: 'orange' }} />
                                <span style={{ fontSize: 20, color: 'orange' }}> Click aqui para upload Imagem</span>
                                <input
                                    type="file"
                                    style={{ display: 'flex' }}
                                    name='imagem'
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => { setimage(e.target.files[0]) }}
                                />
                            </InputFile>
                            <InputFile>
                                <MusicNote style={{ fontSize: 28, color: 'orange' }} />
                                <span style={{ fontSize: 20, color: 'orange' }}>Click aqui para upload Musica</span>
                                <input
                                    type="file"
                                    style={{ display: 'flex' }}
                                    name='audio'
                                    accept=".mp3"
                                    onChange={(e) => { setaudio(e.target.files[0]) }}
                                />
                            </InputFile>
                            {
                                userLogged.tipo === 'Musico' ? 
                                (
                                    <InputFlex>
                                    <Input
                                        type="text"
                                        name="autor"
                                        value={userLogged.name}
                                        placeholder='Nome do Autor...'
                                        onChange={(e) => { setautor(e.target.value) }}
                                    />
                                </InputFlex>
                                ) 
                                : 
                                (
                                    <InputFlex>
                                    <Input
                                        type="text"
                                        name="autor"
                                        id=""
                                        placeholder='Nome do Autor...'
                                        onChange={(e) => { setautor(e.target.value) }}
                                    />
                                </InputFlex>
                                )
                            }
                            <InputFlex>
                                <Input
                                    type="text"
                                    name="title"
                                    id=""
                                    placeholder='Titulo da Musica...'
                                    onChange={(e) => { settitulo(e.target.value) }}
                                />
                            </InputFlex>
                            <InputFlex>
                                <TextArea
                                    placeholder='Descrição da Musica...'
                                    name='descricao'
                                    onChange={(e) => { setdescricao(e.target.value) }}
                                >

                                </TextArea>
                            </InputFlex>
                            <InputFlex>
                                <Select
                                    name='cat'
                                    onChange={(e) => { setcatSelet(e.target.value) }}
                                >
                                    <option value="">Seleciona a categoria da Musica</option>
                                    {
                                        Categoria.map((category, index) => (
                                            <option value={category.id}> {category.categoria} </option>
                                        ))
                                    }
                                </Select>
                            </InputFlex>
                            <ButtonUpload onClick={UploadMusic}>
                                <span style={{ fontSize: 20, fontWeight: 600, color: '#fff', }} >Save</span>
                            </ButtonUpload>
                        </ContainerInputs>
                    </SectionUpload>
                </ContainerUpload>
                <ToastContainer position="top-center" />
            </Background>

        )
    }
    return null
}

export default ModalUploadMusic