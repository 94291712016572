import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MusicCard from '../components/MusicCardFavorites';
import axios from "axios";
import { BASE_URL } from '../api';
import { useNavigate } from 'react-router-dom';
import { Token } from '@mui/icons-material';
const Favorites = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const Topic = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    font-size: 24px;
    font-weight: 500;
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const FavoriteContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 14px;
   padding: 18px 6px;
   @media (max-width: 550px){
      justify-content: center;
   }
`;

const Favorite = () => {
  const [MusicCat, setMusicCat] = useState([]);
  const Navigation = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('id')
    if (!token) {
      Navigation('/');
    } else {
      const HandleSearch = async () => {
        try {
          await axios.get(`${BASE_URL}/favorites/users/${id}`)
            .then(res => {
              setMusicCat(res.data.Response);
            })
            .catch(err => console.log(err));
        } catch (error) {
          console.log('You are Offline')
        }
      }
      HandleSearch();
    }
  })

  return (
    <Favorites>
      <Topic>
        Meus Favoritos
      </Topic>
      <FavoriteContainer>
        {
          MusicCat.map((MusicCat) => (
            <MusicCard music={MusicCat} />
          ))
        }

      </FavoriteContainer>
    </Favorites>
  )
}

export default Favorite
