import MusicCardCat from '../components/MusicCardCategoria';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from '../api';
import { useParams } from 'react-router-dom';

const Favorites = styled.div`    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const Topic = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    font-size: 24px;
    font-weight: 500;
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const FavoriteContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 14px;
   padding: 18px 6px;
   @media (max-width: 550px){
      justify-content: center;
   }
`;

const Displaymusic = () => {

  const [MusicCat, setMusicCat] = useState([]);
  const [Cat, setCat] = useState('');

  const params = useParams();
  const id = params['id'];

  useEffect(() => {
    HandleSearch();
  })

  const HandleSearch = async () => {
    try {
      await axios.get(`${BASE_URL}/audio/music/cat/${id}`)
        .then(res => {
          setMusicCat(res.data.Response);
          setCat(res.data.Response[0].categoria);
          })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }

  return (
    <Favorites>
      <Topic>
        {Cat}
      </Topic>
      <FavoriteContainer>
        {
          MusicCat.map((MusicCat) => (
            <MusicCardCat music={MusicCat} />
          ))
        }
      </FavoriteContainer>
    </Favorites>
  )
}

export default Displaymusic
