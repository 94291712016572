import React from "react";
import Container from "./components/Container";
import { LoginContextProvider } from "./context/LoginConext";

function App() {
 
   return (
      <LoginContextProvider >
          <Container/>
       </LoginContextProvider>
   );
}

export default App;
