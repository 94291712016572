import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from "axios";
import { BASE_URL } from '../api';
import MusicCardDownloads from '../components/MusicCardTable';
import { useNavigate } from 'react-router-dom';
const Favorites = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const Topic = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    font-size: 24px;
    font-weight: 500;
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const FavoriteContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 14px;
   padding: 18px 6px;
   @media (max-width: 550px){
      justify-content: center;
   }
`;

const Downloads = () => {

  const [MusicCat, setMusicCat] = useState([]);
  const Navigation = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      Navigation('/');
    } else {
      const HandleSearch = async () => {
        try {
          await axios.post(`${BASE_URL}/users/aprove/`)
            .then(res => {
              setMusicCat(res.data.Response);
            })
            .catch(err => console.log(err));
        } catch (error) {
          console.log('You are Offline')
        }
      }
      HandleSearch();
    }
  })
  return (
    <Favorites>
      <Topic>
       Faça aprovação de Músicos e Gestores
      </Topic>
      <FavoriteContainer>
        {
          MusicCat.map((Musics) => (
            <MusicCardDownloads music={Musics} />
          ))
        }
      </FavoriteContainer>
    </Favorites>
  )
}

export default Downloads
