import { MenuRounded, PersonRounded, Notifications, Close, PlayArrow } from '@mui/icons-material';
import { BASE_URL } from '../api';
import axios from 'axios';
import { LoginContext } from '../context/LoginConext';
import { Avatar, IconButton } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
const NavibarDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 40px;
    align-items: center;
    color: ${({ theme }) => theme.text_primary};
    gap:30px;
    background: ${({ theme }) => theme.bgLight};
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter:blur(5.7px);
    -webkit-backdrop-filter:blur(5.7px);
    @media(max-width: 768px){
        padding: 16px;
    }
`;
const ButtonDiv = styled.button`
    font-size: 14px;
    cursor: pointer;
    max-width: 90px;
    display:flex;
    align-items:center;
    color: ${({ theme }) => theme.text_primary};
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 6px;
    padding: 8px 10px;
    background: orange;
    gap: 8px;   
`;
const IcoButton = styled(IconButton)`
     color: ${({ theme }) => theme.text_secondary} !important;
`;
const UserName = styled.span`
    font-size: 18px;
    font-weight: 600;
     color: ${({ theme }) => theme.text_secondary} !important;
    @media(max-width: 768px){
        display:none;
    }
`;
const IcoButtonNoti = styled(Notifications)`
    margin-top: 10px;
    color: orange;
    @media(max-width: 768px){
        display: none;
        margin-top: 0px;
    }
`;
const ContainerImageUser = styled.div``;
const Span = styled.div`
  font-size: 12px;
  margin-top: -15px; 
  margin-right: -30px;
  padding: 5px; 
  border-radius: 50px;
  background: orange;
`;
const Fundo = styled.div`
    width: 45px;
    height: 45px;
    background: ${({ theme }) => theme.bg};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
`;
const AreaComent = styled.div`
    display: flex;
    width: 450px;
    height: 430px;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffff;
    border-radius: 20px;
     @media(max-width: 768px){
        width: auto;
    }
    
`;
const AreaUserComented = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`;
const ListUser = styled.div`
     display: flex;
     width: 100%;
     height: 100%;
     gap: 8px;
     flex-direction: column;  
`;
const ImageUser = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50px;
    object-fit: fill;
`;
const ContainerComent = styled.div`
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.7);
    z-index: 1000;
`;
const AreaHeader = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 15px;
`;
const ContainerComentario = styled.div`
   margin-top: 4px;
   margin-left: 40px; 
   display: flex;
   width: 80%;
   height: 80px;
   border-radius: 20px;
   padding: 10px;
   background:#f0f0f0;
`;
const SpanN = styled.div`
    -webkit-line-clamp: 1;
    font-size: 12px;
`;
const Div = styled.div`
  overflow-x:hidden;
`;

const Navbar = ({ menuOpen, setMenuOpen, setIsEditOpen }) => {
  const [Isauth, setIsauth] = useState(false);
  const [iscoment, Setiscoment] = useState(false);
  const [iscomentData, SetiscomentData] = useState([]);
  const [Isnotification, setIsnotification] = useState(true);
  const NavigateTo = useNavigate();
  const { userLogged } = useContext(LoginContext);

  const AccessToken = localStorage.getItem('token');
  useEffect(() => {
    if (AccessToken) {
      setIsauth(true);
      setIsnotification(false)
    }
    else {
      setIsauth(false)
    }
  }, [])
  const CloseModal = () => {
    setIsnotification(false)
    Setiscoment(false)
  }

  const GetAllNotification = async () => {
    Setiscoment(prev => !prev)
    try {

      await axios.get(`${BASE_URL}/notification/`)
        .then(res => {
          SetiscomentData(res.data.Response)
          setIsnotification(true)
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('Networck Erro')
    }
  }

  return (
    <div>
      {
        iscoment ? (
          <ContainerComent>
            <AreaComent>
              <div style={{
                margin: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <span style={{ fontSize: '20px', }} >Notificação</span>
                <Close onClick={CloseModal} />
              </div>
              <Div >
                <AreaUserComented>
                  <ListUser>
                    {
                      iscomentData.map((notificationData, index) => (
                        <Link
                          onClick={CloseModal}
                          to={`/music/${notificationData.id}`}
                          style={{ textDecoration: "none", color: '#000' }}
                        >
                          <ContainerComentario>
                            <AreaHeader>
                              <img
                                src={`${BASE_URL}/uploads/` + notificationData.imagem}
                                style={{
                                  borderRadius: 50,
                                  width: 80,
                                  height: 80,
                                  resize: 'unset',
                                  fontSize: '24px',
                                }}
                                alt='logo'
                              />
                              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: -8, }} >
                                <SpanN>{notificationData.titulo}</SpanN>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }} >
                                  <SpanN style={{ fontSize: '10px' }} >{notificationData.name}</SpanN>
                                  <SpanN style={{ fontSize: '9px', color: 'orange' }} >{notificationData.tipo}</SpanN>
                                  <PlayArrow style={{ fontSize: '20px', color: 'orange', marginLeft: 80, }} />
                                </div>
                                <SpanN style={{ fontSize: '10px' }}>{notificationData.created_at}</SpanN>
                              </div>
                            </AreaHeader>
                          </ContainerComentario>
                        </Link>
                      ))
                    }
                  </ListUser>
                </AreaUserComented>
              </Div>
            </AreaComent>

          </ContainerComent>
        ) : (<></>)
      }

      <NavibarDiv>
        {
          Isauth === true ?
            (<>
              <IcoButton onClick={() => setMenuOpen(!menuOpen)}> <MenuRounded />
              </IcoButton>
              <UserName> Bem vindo Sr.{userLogged.name} </UserName>

              <Fundo onClick={GetAllNotification}>
                {
                  Isnotification ? (<Span></Span>) : ''
                }

                <IcoButtonNoti />
              </Fundo>
              <Link to='/profile' style={{ textDecoration: 'none' }} >
                <ContainerImageUser>
                  {
                    userLogged.fotoperfil ?
                      (<ImageUser src={`${BASE_URL}/fotosperfil/` + userLogged.fotoperfil} alt={userLogged.name} />)
                      :
                      (<Avatar sx={{ height: 45, width: 45 }}></Avatar>)
                  }
                </ContainerImageUser>
              </Link>
            </>
            ) :
            (<> <IcoButton onClick={() => setMenuOpen(!menuOpen)}> <MenuRounded />
            </IcoButton>
              <ButtonDiv
                onClick={() => setIsEditOpen(true)}
              >
                <PersonRounded />
                Login
              </ButtonDiv></>)
        }
      </NavibarDiv>
    </div>
  )
}

export default Navbar
