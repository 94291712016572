import React, {
  useState
} from 'react';

import {
  Routes,
  Route
} from 'react-router-dom';


import Home from '../pages/Home';
import Search from "../pages/Search";
import Favorite from '../pages/Favorite';
import MusicDatsils from '../pages/MusicDatails';
import Displamusic from '../pages/Displaymusic';
import PlayList from '../pages/PlayList';
import Downloads from '../pages/Downloads';
import Profile from '../pages/Profile';
import ModalLogin from '../components/ModalLogin';
import ModalRegister from './ModalRegister';
import MusicCardDisplayHome from '../pages/DisplaymusicHome'
import AdminPage from '../pages/Admin'

import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './../utils/Themes';

import Sidbar from './../components/Sidbar';

import Navbar from './../components/Navbar';
import ModalUploadMusic from './ModalUploadMusic';


const Conatiner = styled.div`
    display: flex;
    background: ${({ theme }) => theme.bgLight};
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  `;
const Frame = styled.div`
       display: flex;
       flex-direction: column;
       flex: 3;
  `;
function Container() {
  //Hooks
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [IsRegister, setIsRegister] = useState(false);
  const [darckMode, setdarckMode] = useState(true);
  const [menuOpen, setMenuOpen] = useState(true);
  const [IsModalUpMusic, setIsModalUpMusic] = useState(false);
  return (
    <ThemeProvider theme={darckMode ? darkTheme : lightTheme}>
      <Conatiner>
        {menuOpen && (
          <Sidbar
            setIsModalUpMusic={setIsModalUpMusic}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            setdarckMode={setdarckMode}
            darckMode={darckMode}
          />
        )
        }
        <Frame>
          <Navbar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            setIsEditOpen={setIsEditOpen}
          />
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/search' element={<Search />} />
            <Route path='/favorite' element={<Favorite />} />
            <Route path='/music/:id' element={<MusicDatsils />} />
            <Route path='/music/display' element={<MusicCardDisplayHome />} />
            <Route path='/playlist/' element={<PlayList />} />
            <Route path='/showmusic/:id' element={<Displamusic />} />
            <Route path='/downloads' element={<Downloads />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/admin' element={<AdminPage />} />
          </Routes>
          {/** ----------------------------Renderizando Modal----------------------------------------------------- */}
          <ModalLogin
            IsRegister={IsRegister}
            setIsRegister={setIsRegister}
            isEditOpen={isEditOpen}
            setIsEditOpen={() => setIsEditOpen(!isEditOpen)}
          />
          <ModalRegister
            IsRegister={IsRegister}
            setIsRegister={() => setIsRegister(!setIsRegister)}
            setIsEditOpen={setIsEditOpen}
           
          />
          <ModalUploadMusic
            IsModalUpMusic={IsModalUpMusic}
            setIsModalUpMusic={() => setIsModalUpMusic(!setIsModalUpMusic)}
          />
          {/** ----------------------------End Modal----------------------------------------------------- */}
          
        </Frame>
      </Conatiner>
    </ThemeProvider>

  );
}

export default Container;
