
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import MusicCard from '../components/MusicCard';
import { BASE_URL } from '../api';
import axios from 'axios';
const Dashboard = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg};
  border-radius: 10px;
  padding: 20px 30px;
`;
const Topic = styled.div`
    color: ${({ theme }) => theme.text_primary};
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center; 
    @media(max-width: 768px){
        font-size: 18px;
    }
`;
const Span = styled.div`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    @media(max-width: 768px){
        font-size: 14px;
    }
`;
const Music = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 14px;
   padding: 18px 6px;
   @media (max-width: 550px){
      justify-content: center;
   }
`;
const Home = () => {

  const[MusicApi, setMusicApi] = useState([]);

  useEffect(() => {
    GetAllMusic();
  }, []) 

  const GetAllMusic = async () => {
    try {
      await axios.get(`${BASE_URL}/audio`)
        .then(res => {
          if (res) {
            setMusicApi(res.data.Response);        
          } else {
            toast.warn('You are Offline')
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }
  return (
    <Dashboard>

      <FilterContainer>
        <Topic>
          DESTAQUE
          <Link to={`/music/display`}
            style={{ textDecoration: 'none' }} >
            <Span>Ver Todas</Span>
          </Link>
        </Topic>
        <Music>
          {MusicApi.map((Musics, index) => (
            <MusicCard
              music={Musics}
              index={index}
            />
          )
          )}
        </Music>
      </FilterContainer>
      <ToastContainer position="top-center" />
    </Dashboard>
  )
}

export default Home
