import { SearchOutlined } from '@mui/icons-material'
import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from "axios";
import {
  Headphones,
  Pause,
  Download,
} from '@mui/icons-material'
import { Link } from 'react-router-dom';
import CategoriesCard from '../components/CategoriesCard';
import { CircularProgress } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from '../api';

const SearchMain = styled.div`
    padding: 20px 30px;
    padding-bottom: 200px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media(max-width: 768px){
        padding: 6px 10px;
    }
`;
const SearchBar = styled.div`
  max-width: 700px;
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  border-radius: 30px;
  cursor: pointer;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.text_secondary};
`;
const Categories = styled.div`
    margin: 20px 10px;
`;
const Heading = styled.div`
     align-items: flex-start;
     font-size: 22px;
     font-weight: 540;
     margin: 10px 14px;
      color: ${({ theme }) => theme.text_secondary};
`;
const BrowseAll = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 14px;
`;
const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const CreatorInfo = styled.div`
   display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 6px;
`;
const Creator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
`;
const PlayIcon = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: orange;
    border-radius: 50%;
    color: white !important;
    box-shadow: 0 0 10px 4px #51505350 !important;
`;
const ContainerMusicsDatails = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.bg};
    border-radius: 10px;
    padding: 20px 30px;
`;
const AllmusicCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    @media(max-width: 614px){
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;
const MusicImg = styled.img`
      border-radius: 6px;
      width: 130px;
      height: 80px
      object-fit: fill;
      display: flex;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4); 
     @media(max-width: 614px){
        width: 250px;
        height: 190px;
    }
  &:hover{
        box-shadow: 0 4px 30px white; 
    }
`;
const MusilInfor = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    gap: 8px;
    @media(max-width: 614px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;
const MusicTitle = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 14px;
    color: ${({ theme }) => theme.text_primary};
`;
const DescriptionMusic = styled.div`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text_secondary};
    font-size: 15px;
    @media(max-width: 830px){
       -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       text-overflow: ellipsis;
    }
`;


const CardAllMusicUser = ({ music }) => {
  const [isplay, SetisPlay] = useState(false);

  const audioRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.play();
    SetisPlay(true);

  }
  const handlePause = () => {
    audioRef.current.pause();
    SetisPlay(false);
  }
  const handlePlayPause = () => {
    if (isplay) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  return (
    <ContainerMusicsDatails>
      <AllmusicCard>
        <Link to={`/music/${music.id}`}>
          <MusicImg src={`${BASE_URL}/uploads/` + music.imagem} />
        </Link>
        <MusilInfor>
          <MusicTitle>{music.titulo} </MusicTitle>
          <DescriptionMusic>
            {music.descricao}
          </DescriptionMusic>
          <DescriptionMusic>
            {music.autor}
          </DescriptionMusic>
          <CreatorInfo>
            <audio ref={audioRef} src={`${BASE_URL}/uploads/` + music.musica} />
            <Creator>
              <PlayIcon onClick={handlePlayPause} >
                {isplay ?
                  <Pause style={{ width: "14px", height: "14px" }} />
                  :
                  <Headphones style={{ width: "14px", height: "14px" }} />}
              </PlayIcon>
            </Creator>
          </CreatorInfo>
        </MusilInfor>
      </AllmusicCard>
      
    </ContainerMusicsDatails>

  );
}


const Search = () => {

  const [searched, setsearched] = useState('');
  const [Campo, setCampo] = useState(false);
  const [searchedMusic, setsearchedMusic] = useState([])
  const [loading, setloading] = useState(false);
  const [categorias, setCategoria] = useState([]);

  // const [titulo, autor] = searched.split(',');

  // const data = {
  //   titulo: titulo,
  //   autor: autor,
  // };
  const HandleSearch = async () => {
    try {
      await axios.post(`${BASE_URL}/audio/music/search`, { titulo: searched })
        .then(res => {
          if (res.data.Response.length < 1) {
            toast.warn('Nenhuma musica encontrada!')
          } else if (res) {
            setsearchedMusic(res.data.Response)
            setCampo(false)
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }

  useEffect(() => {
    GetAllCategory();

  }, [])

  const GetAllCategory = async () => {
    try {
      await axios.get(`${BASE_URL}/categorias`)
        .then(res => {
          if (res) {
            setloading(true)
            setCategoria(res.data.Response);
          } else {
            toast.warn('You are Offline')
          }
        })
        .catch(err => console.log(err));
    } catch (error) {
      toast.warn('You are Offline')
    }
  }

  const hendleChange = async (e) => {
    setsearched(e.target.value)
    setCampo(true)
    setloading(true);
  };

  return (
    <SearchMain>
      <div style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}>
        <SearchBar >
          <input
            type='text'
            placeholder='Pesquiza por Titulo ou Autor da Musica'
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              background: "inherit",
              color: "inherit",
            }}
            value={searched}
            onChange={(e) => hendleChange(e)}
          />
          <SearchOutlined onClick={HandleSearch} />
        </SearchBar>
      </div>
      {searched === "" ? (
        <Categories>
          <Heading>Todas Categorias</Heading>
          <BrowseAll>
            {categorias.map((Category) => (
              <Link
                to={`/showmusic/${Category.id}`}
                style={{ textDecoration: "none", }}
              >
                <CategoriesCard category={Category} />
              </Link>
            )
            )}
          </BrowseAll>
          {loading ?
            (<></>) :
            (
              <Loader>
                <CircularProgress style={{ color: "orange" }} />
              </Loader>
            )

          }
        </Categories>
      ) : (
        <>
          {Campo ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ color: 'orange', fontSize: '24px', fontWeight: '500' }} >Musica não encontrada</span>
            </div>
          ) : (
            <>
              {
                searchedMusic.map((searchedMusic) => (
                  <CardAllMusicUser music={searchedMusic} />
                ))
              }
            </>
          )}
        </>
      )
      }
      <ToastContainer position="top-center" />
    </SearchMain>
  )
}
export default Search
