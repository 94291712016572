import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from './../images/logo1.png';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoginContext } from '../context/LoginConext';
import "react-toastify/dist/ReactToastify.css";
import {
    HomeRounded,
    CloseRounded,
    LogoutRounded,
    FavoriteRounded,
    SearchRounded,
    LightModeRounded,
    DarkModeRounded,
    CloudUpload,
    CloudDownload,
    QueueMusic,
    Person,
    AdminPanelSettings
} from '@mui/icons-material';

const MenuContainer = styled.div`
    flex: 0.5;
    flex-direction: column;
    height: 100vh;
    display: flex; 
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.text_primary};
    @media (max-width: 1100px){
    position: fixed;
    z-index:1000;
    width: 100%;
    max-width: 250px;
    left: ${({ menuOpen }) => (menuOpen ? "0" : "-100%")};
    transition: 0.3s ease-in-out;
}
`;
const Image = styled.img`
    height: 40px;
`;
const Flex = styled.div`
    display: flex;
    flex-direction: row;
     align-items:center;
     justify-content: space-between;
     padding: 0px 12px;
`;
const Logo = styled.div`
     color: ${({ theme }) => theme.primary};
     display: flex;
     align-items:center;
     justify-content: center;
     gap: 6px;
     font-weight: bold;
     font-size: 20px;
     margin: 16px 0px;
`;
const Close = styled.div`
    display:none;
    @media (max-width: 1100px){
    display:block;
    }
`;
const Elements = styled.div`
    padding: 4px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    gap: 12px;
    cursor: pointer;
    color:${({ theme }) => theme.text_secondary};
    width: 87%;
    text-decoration: none !important;
    &:hover {
        background-color:${({ theme }) => theme.text_secondary + 50};
    }
`;
const NavText = styled.div`
    padding: 12px 0px;
    text-decoration: none !important;
`;
const HR = styled.div`
    width: 100%;
    height: 1px;
    background-color:${({ theme }) => theme.text_secondary + 50};
    margin: 10px 0px;
`;

const Sidbar = ({ menuOpen, setMenuOpen, setdarckMode, darckMode, setIsModalUpMusic }) => {
    const navigateTo = useNavigate();
    const [IsLogged, setIsLogged] = useState(false);
    const { userLogged } = useContext(LoginContext);

    const VeriFyToken = async () => {
        const Token = localStorage.getItem('token');
        setIsLogged(Token);
    }
    useEffect(() => {
        VeriFyToken();
    }, []);


    const Logout = async (e) => {
        if (IsLogged) {
            navigateTo('/');
            window.location.reload()
            localStorage.removeItem('token');
            localStorage.removeItem('id');
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }

    const Goto = () => {
        if (IsLogged) {
            navigateTo('/downloads')
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }
    const GotoF = () => {
        if (IsLogged) {
            navigateTo('/favorite')
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }
    const GotoProfile = () => {
        if (IsLogged) {
            navigateTo('/profile')
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }
    const GotoAdmin = () => {
        if (IsLogged) {
            navigateTo('/admin')
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }
    const GotoUpload = () => {
        if (IsLogged) {
            setIsModalUpMusic(true)
        } else {
            toast.warn('Voce não está Autorizado!')
        }
    }
    return (
        <MenuContainer menuOpen={menuOpen}>
            <Flex>
                <Logo>
                    <Image src={LogoImage} />
                    </Logo>
                <Close onClick={() => setMenuOpen(false)}>
                    <CloseRounded />
                </Close>
            </Flex>

            <Link style={{ textDecoration: "none", }} to="/">
                <Elements>
                    <HomeRounded />
                    <NavText>Painel</NavText>
                </Elements>
            </Link>
            <Link style={{ textDecoration: "none", }} to="/search">
                <Elements>
                    <SearchRounded />
                    <NavText> Pesquiza </NavText>
                </Elements>
            </Link>
            <div style={{ textDecoration: "none", }} onClick={GotoF}>
                <Elements>
                    <FavoriteRounded />
                    <NavText> Favoritos </NavText>
                </Elements>
            </div>
            <div style={{ textDecoration: "none", }} onClick={Goto}>
                <Elements>
                    <CloudDownload />
                    <NavText> Downloads </NavText>
                </Elements>
            </div>

            <Link style={{ textDecoration: "none", }} to="/playlist">
                <Elements>
                    <QueueMusic />
                    <NavText> PlayList </NavText>
                </Elements>
            </Link>
            <div style={{ textDecoration: "none", }} onClick={GotoProfile}>
                <Elements>
                    <Person />
                    <NavText> Perfil </NavText>
                </Elements>
            </div>
            <div style={{ textDecoration: "none", }} onClick={GotoAdmin}>
                {
                    IsLogged ?
                        (
                            userLogged.tipo === "admin" ?
                                (<Elements  >
                                    <AdminPanelSettings />
                                    <NavText> Aprovar Contas </NavText>
                                </Elements>)
                                :
                                (<></>)
                        )
                        :
                        (
                           <></>
                        )
                }
            </div>
            <HR />
            {
                IsLogged ?
                    (
                        userLogged.tipo === "Ouvinte" ?
                            (<></>)
                            :
                            (<Elements onClick={GotoUpload}>
                                <CloudUpload />
                                <NavText> Upload </NavText>
                            </Elements>)
                    )
                    :
                    (
                        <Elements onClick={GotoUpload}>
                            <CloudUpload />
                            <NavText> Upload </NavText>
                        </Elements>
                    )
            }
            <Elements onClick={() => setdarckMode(!darckMode)}>
                {darckMode ? <LightModeRounded /> : <DarkModeRounded />}
                <NavText> {darckMode ? "Light Mode" : "Dark Mode"} </NavText>
            </Elements>

            <Elements onClick={Logout}>
                <LogoutRounded />
                <NavText> Sair </NavText>
            </Elements>
            <ToastContainer position="top-center" />
        </MenuContainer>
    )
}

export default Sidbar
