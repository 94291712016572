import React, { useContext, useState } from 'react'
import { Close, Google } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { LoginContext } from '../context/LoginConext';
const background = {
    display: 'flex',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
    zIndex: '1000',
    
}
const modal = {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    height: '480px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffff',
    borderRadius: '10px',
}
const TopArea = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '30px',
    padding: '25px',
}
const TitleArea = {
    fontSize: '20px',
    fontweight: '500',
}
const ButtonClose = {
    border: 'none',
    backgroundColor: 'transparent',
}
const SectionModal = {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '25px',
    gap: 20,
}

const ContainerInputs = {
    width: '100%',
    marginTop: -20,
}
const InputGoolge = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    justifyContent: 'center',
    width: '80%',
    height: '40px',
    border: '1px solid gray',
    borderRadius: '15px',
    padding: 5,
}
const Input = {
    width: '80%',
    height: '40px',
    border: '1px solid gray',
    borderRadius: '15px',
    padding: 10,
}
const Divider = {
    marginLeft: -70,
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignSelf: 'center',
    marginBottom: 20,
}
const BorderDiv = {
    width: '100%',
    borderBottom: '1px solid #000',
}
const ContainerInputs2 = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    marginTop: -20,
}
const ButtonLogin = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: '50px',
    border: 'none',
    borderRadius: '10px',
    padding: 15,
    marginLeft: -65,
    fontSize: 18,
    backgroundColor: 'orange'
}
const Forgot = {
    fontSize: 14,
    fontWeight: 500,
    color: 'gray',
    cursor: 'Pointer',
    marginLeft: '55%',
}
const Label = {
    fontSize: 14,
    fontWeight: 400,
}
const ModalLogin = ({ isEditOpen, setIsEditOpen, setIsRegister }) => {
    const { Login } = useContext(LoginContext);
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const OpenRegisterModal = () => {
        setIsRegister(true);
        setIsEditOpen(false);
    }
    const Login2 = () => {
        try {
            Login({ email, password })

        } catch (error) {
            setIsEditOpen(true);
        }

    }
    if (isEditOpen) {
        return (
            <div style={background}>
                <div style={modal}>
                    <div style={TopArea}>
                        <span style={TitleArea}>Faça Login</span>
                        <button style={ButtonClose}
                            onClick={setIsEditOpen}>
                            <Close />
                        </button>
                    </div>
                    <div style={SectionModal}>
                        <div style={ContainerInputs}>
                            {/* <div style={InputGoolge}>
                                <Google />
                                <span>Sing In with Google</span>
                            </div> */}
                        </div>
                        {/* <div style={Divider} >
                            <span style={BorderDiv}></span>
                            or
                            <span style={BorderDiv}></span>
                        </div> */}
                        <div style={ContainerInputs2}>
                            <span style={Label}>Email</span>
                            <input
                                style={Input}
                                type="email"
                                name=""
                                id=""
                                placeholder='Insera o teu Email'
                                onChange={(event) => { setemail(event.target.value) }}
                            />
                            <span style={Label}>Password</span>
                            <input
                                style={Input}
                                type="password"
                                name=""
                                id=""
                                placeholder='Insira a tua Password'
                                onChange={(event) => { setpassword(event.target.value) }}
                            />
                            {/* <Link
                                href='#'
                                style={Forgot}>
                                Forgot Password?
                            </Link> */}
                        </div>
                        <button
                            onClick={Login2}
                            style={ButtonLogin}>
                            <span>Entrar</span>
                        </button>
                    </div>
                    <button
                        onClick={OpenRegisterModal}
                        style={{ border: 'none', background: 'transparent' }}
                    >
                        <span>Se não tens uma Conta? click Aqui!</span>
                    </button>

                </div>

            </div>
        )
    }
    return null;
}

export default ModalLogin